.dark-header {
  background-color: #333;
  color: #fff;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
}
h1 {
  font-size: 20px;
}
.Headerbutton {
  display: flex;
  gap: 15px;
}